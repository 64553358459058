<template>
  <div>
    <gc-dialog title="基本参数配置" :show.sync="visible" width="610px">
      <template>
        <el-form
          ref="configModel"
          :model="configModel"
          :rules="rules"
          :disabled="status === 'check'"
          label-position="top"
        >
          <el-form-item
            v-for="{ key, describe } in configList"
            :key="key"
            :label="describe"
            :prop="key"
            required
          >
            <!-- 单独判断告警播放时长为输入框格式 -->
            <el-input
              v-if="key === 'alarm_sound_duration'"
              v-model="configModel[key]"
              placeholder="请输入告警播放时长"
            />
            <el-radio-group v-else v-model="configModel[key]">
              <el-radio :label="true" value="1">是</el-radio>
              <el-radio :label="false" value="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="" v-if="status === 'check'">
          <el-button
            type="primary"
            round
            @click="status = 'edit'"
            v-has="'utos:tenant:maintain'"
            >编辑</el-button
          >
        </div>
        <div class="" v-else-if="status === 'edit'">
          <el-button round @click="cancelEdit">取消</el-button>
          <el-button round @click="updateTenant" type="primary">保存</el-button>
        </div>
      </template>
    </gc-dialog>
  </div>
</template>

<script>
import { apiSetTenantConfigs, apiGetTenantConfigs } from "@/api/tenant.js";
import { ruleRequired, RULE_SOUND_DURATION } from "@/utils/rules";

export default {
  name: "ModifyConfig",
  components: {},
  props: {},
  data() {
    return {
      configModel: {},
      configList: [],
      visible: false,
      status: "check", // check edit
      initialModel: {},
      rules: {
        alarm_sound_duration: [
          ruleRequired("请输入声音播放时长"),
          RULE_SOUND_DURATION,
        ],
      },
    };
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.$refs.configModel?.resetFields();
      }
    },
  },
  computed: {
    tenantId() {
      return this.$route.query.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    getConfigList() {
      return new Promise((resolve) => {
        apiGetTenantConfigs({ tenantId: this.tenantId }).then(
          ({ dataList }) => {
            // dataList = [
            //   ...dataList,
            //   {
            //     describe: "告警播放时长",
            //     key: "alarm_sound_duration",
            //     value: 10,
            //   },
            // ];
            dataList.forEach((item) => {
              const { key, value } = item;
              this.$set(
                this.initialModel,
                key,
                key === "alarm_sound_duration"
                  ? value
                  : Boolean(value === "true")
              );
            });
            this.configModel = this._.cloneDeep(this.initialModel);
            this.configList = dataList;
            resolve(dataList && dataList.length > 0);
          }
        );
      });
    },
    show() {
      this.getConfigList().then((res) => {
        if (res) {
          console.log(this.configModel, this.initialModel);
          this.status = "check";
          this.visible = true;
        }
      });
    },
    cancelEdit() {
      this.status = "check";
      this.configModel = this._.cloneDeep(this.initialModel);
    },
    updateTenant() {
      this.$refs.configModel.validate((valid) => {
        if (valid) {
          const configs = Object.entries(this.configModel).map((item) => {
            return {
              key: item[0],
              value: item[1],
            };
          });
          apiSetTenantConfigs({
            configs,
            tenantId: this.tenantId,
          }).then(() => {
            this.$message.success("操作成功");
            this.visible = false;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.split-line {
  width: 100%;
  border: 0.5px dashed #ccc;
  height: 0;
  transform: scaleY(0.5);
}
.pale-title {
  margin: 19px 0 20px;
  flex: 0 0 100%;
  font-size: 14px;
  color: #ababab;
  line-height: 14px;
}
::v-deep .el-form {
  margin-top: 19px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item {
    flex: 0 0 calc((100% - 30px) / 2);
    margin-right: 0;
    &:nth-child(2n),
    &.margin-right-30px {
      // margin-right: 30px;
    }
    &.original {
      margin-right: 0;
    }
    &.col-12 {
      flex: 0 0 100%;
      margin-right: 0;
    }
    .el-select {
      width: 100%;
    }
    .el-form-item__label {
      line-height: 14px;
      height: 14px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}
</style>
