<template>
  <div class="page">
    <gc-header :header-data="headerData">
      <!-- <div class="status-self" slot="status">
        <div class="dot normal"></div>
        正常
      </div> -->
      <template #ops>
        <!-- <el-button type="primary" @click="updateAccount">账户管理</el-button> 二期暂不支持 -->
        <el-button type="primary" @click="checkDetail">查看详情</el-button>
        <el-button
          type="warning"
          plain
          @click="checkConfig"
          v-if="showConfigBtn"
          v-has="'utos:tenant:select'"
          >参数配置</el-button
        >
        <el-button type="warning" plain @click="changeTenantStatus">{{
          tenantDetail.enable ? "禁用" : "启用"
        }}</el-button>
      </template>
    </gc-header>
    <div class="page-main" v-loading.lock="showLoading">
      <gc-detail-tab
        v-if="showDetailTab"
        :tab-list="tabList"
        :default-active-name.sync="defaultActiveName"
      ></gc-detail-tab>
    </div>

    <modify-account ref="modifyAccountDialog" />
    <add-tenant ref="modifyTenantModel" @emit-refresh="getTenantDetail" />
    <config-model ref="configModel" />
  </div>
</template>

<script>
import Organization from "./components/Organization.vue";
import Device from "./components/Device.vue";
import Operate from "./components/Operate.vue";
import Permission from "./components/Permission.vue";
import ModifyAccount from "./components/ModifyAccount.vue";
import AddTenant from "../tenantManage/components/AddTenant.vue";
import ConfigModel from "./components/ModifyConfig.vue";
import {
  apiEnableTenant,
  apiDisableTenant,
  apiGetTenantDetail,
} from "@/api/tenant";
export default {
  name: "TenantDetail",
  data() {
    return {
      visible: false,
      tenantDetail: {},
      showDetailTab: true,
      showLoading: false,
      showConfigBtn: false,
      defaultActiveName: null,
    };
  },
  components: {
    ModifyAccount,
    AddTenant,
    ConfigModel,
  },
  computed: {
    tabList() {
      const { orgEnable } = this.tenantDetail;
      const organizeTab = orgEnable
        ? [
            {
              name: "organization",
              label: "组织结构分配",
              component: Organization,
              data: {},
            },
          ]
        : [];
      return organizeTab.concat([
        {
          name: "permission",
          label: "权限分配",
          component: Permission,
          data: {
            type: 1,
          },
        },
        {
          name: "device",
          label: "设备类型开通",
          component: Device,
          data: {
            type: 2,
          },
        },
        {
          name: "operate",
          label: "操作记录",
          component: Operate,
          data: {
            type: 3,
          },
        },
      ]);
    },

    tenantId() {
      return this.$route.query.tenantId;
    },
    headerData() {
      const { name, subdomain, additionalInfo } = {
        ...this.tenantDetail,
        additionalInfo: this.tenantDetail.additionalInfo || {},
      };

      return {
        pic: "tenant-detail",
        title: name || "--",
        desc: [
          {
            icon: "icon-kehubianhao",
            text: additionalInfo.customerNumber || "未填写",
          },
          {
            icon: "icon-lianxiren",
            text: additionalInfo.contact || "未填写",
          },
          {
            icon: "icon-lianxidianhua",
            text: additionalInfo.phone || "未填写",
          },
          {
            icon: "icon-yuming",
            text: subdomain || "未填写",
          },
        ],
      };
    },
  },
  watch: {
    tabList: {
      handler() {
        this.defaultActiveName = this.tabList[0].name;
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.getTenantDetail().then(() => {
      this.showDetailTab = false;
      this.$nextTick(() => {
        this.showDetailTab = true;
      });
    });
    this.$refs.configModel.getConfigList().then((res) => {
      this.showConfigBtn = res;
    });
  },
  methods: {
    getTenantDetail() {
      this.showLoading = true;
      if (!this.tenantId) return this.$message.error("租户id不存在");
      return apiGetTenantDetail(this.tenantId)
        .then((res) => {
          this.tenantDetail = res;
          Promise.resolve();
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    checkConfig() {
      this.$refs.configModel.show(this.tenantId);
    },
    handleSave() {},
    updateAccount() {
      this.$refs.modifyAccountDialog.show();
    },
    checkDetail() {
      this.$refs.modifyTenantModel.show("check", this.tenantDetail);
    },
    // 改变租户状态 禁用、启用
    changeTenantStatus() {
      //
      const { enable } = this.tenantDetail;
      this.$confirm(`你确定要${enable ? "禁用" : "启用"}该租户吗？`)
        .then(() => {
          const req = enable ? apiDisableTenant : apiEnableTenant;
          req({ tenantId: this.tenantId }).then(() => {
            this.$message.success("操作成功");
            this.getTenantDetail();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .info-title {
    display: flex;
    align-items: center;
    .status-self {
      margin-left: 18px;
      width: 64px;
      height: 20px;
      background: #e4f5ec;
      border-radius: 4px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #16a65d;
      .dot {
        margin-right: 10px;
        width: 4px;
        height: 4px;
        background: #16a65d;
        border-radius: 50%;
      }
    }
  }
}
</style>
