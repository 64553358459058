<template>
  <div class="organization page">
    <gc-empty
      :page-type="'organizationManage'"
      v-if="orgStruct.length === 0 && templateList.length === 0"
    ></gc-empty>
    <div class="page-main" v-else>
      <div class="page-main-left" v-if="templateList.length">
        <gc-sub-title icon="" text="结构模板"></gc-sub-title>
        <div class="model-list">
          <div
            class="model-item"
            @click="handleTemplateCheck(item)"
            v-for="item in templateList"
            :key="item.id"
            :class="{
              active: savedId == item.id,
              checked: checkedId == item.id,
            }"
          >
            {{ item.name }}
          </div>
        </div>

        <div class="btn-group" v-if="checkedId">
          <el-button plain size="large" @click="handleCancelEdit"
            >清空</el-button
          >
          <el-button
            @click="handleSave"
            plain
            size="large"
            v-has="'utos:tenant:maintain'"
            >保存</el-button
          >
        </div>
      </div>
      <div class="page-main-right">
        <div class="tip" v-if="orgStatus === 0">请选择组织层级</div>
        <el-tree
          :data="treeData"
          ref="tree"
          node-key="id"
          default-expand-all
          highlight-current
          :expand-on-click-node="false"
          :check-on-click-node="true"
        >
          <span slot-scope="{ data }" class="custom-tree-node">
            <el-checkbox-group
              v-if="orgStatus === 0"
              v-model="checkedItems"
              @change="handleCheckedItemsChange"
            >
              <el-checkbox :label="JSON.stringify(data)">{{
                data.name
              }}</el-checkbox>
            </el-checkbox-group>
            <span v-else>{{ data.name }}</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="当前数据隔离级别"
              placement="top"
              ><i class="iconfont icon-shujugelijibie" v-if="data.isolation"
            /></el-tooltip>
          </span>
        </el-tree>
        <!--  -->
        <!-- <div class="btn-group" v-if="orgStruct.length > 0 && orgStatus === 1">
          <el-button
            @click="updateIsolation"
            plain
            size="large"
            v-has="'utos:tenant:maintain'"
            >设置数据隔离级别</el-button
          >
        </div> -->
      </div>
    </div>
    <update-data-isolation-level
      ref="updateDialog"
      :org-struct="orgStruct"
      @refresh="getOrgStruct"
    ></update-data-isolation-level>
  </div>
</template>

<script>
import UpdateDataIsolationLevel from "./UpdateDataIsolationLevel.vue";

import {
  apiGetOrgTemplateList,
  apiGetOrgTemplateContent,
  apiSetOrgTemplate,
  apiGetOrgStruct,
  apiGetIsolation,
} from "@/api/tenant";

export default {
  name: "Organization",
  components: { UpdateDataIsolationLevel },
  props: {},
  data() {
    return {
      treeData: [],
      checkedTemplate: {},
      templateList: [],
      savedId: "",
      checkedItems: [],
      orgStruct: [],
      orgStatus: 0, // 0：未设置组织结构；1：已设置组织结构，未设置数据隔离；2：已设置数据隔离
    };
  },
  computed: {
    isEmpty() {
      return false;
    },
    tenantId() {
      return this.$route.query.tenantId;
    },
    checkedId() {
      return this.checkedTemplate.id;
    },
  },
  activated() {
    this.getOrgStruct();
  },
  watch: {
    "$route.path"(val) {
      if (val != "/tenant/detail") {
        this.$refs.updateDialog?.closeDialog();
        const VModal = document.getElementsByClassName("v-modal")[0];
        if (VModal) {
          VModal.style.display = "none";
        }
      }
    },
  },
  methods: {
    // 清空
    handleCancelEdit() {
      this.checkedItems = [];
    },
    handleCheckedItemsChange() {},
    getOrgTemplateList() {
      apiGetOrgTemplateList().then(({ dataList }) => {
        this.templateList = dataList;
        this.treeData = [];
        if (dataList.length === 0) {
          this.$message.info("查询结果为空");
        } else {
          this.checkedTemplate = dataList[0];
          this.getTemplateContent(this.checkedId);
        }
      });
    },
    // 待完善：获取已应用的组织机构模板
    getOrgStruct() {
      return apiGetOrgStruct({ tenantId: this.tenantId }).then((res) => {
        if (!res) {
          this.orgStruct = [];
          this.orgStatus = 0;
          this.getOrgTemplateList();
        } else {
          this.orgStruct = res.children;
          this.treeData = res.children;
          this.orgStatus = 2;
          apiGetIsolation({ tenantId: this.tenantId })
            .then((isolation) => {
              if (isolation) {
                this.orgStatus = 2;
              } else {
                this.orgStatus = 1;
                this.updateIsolation();
              }
              Promise.resolve();
            })
            .catch((err) => {
              this.orgStatus = 1;
              this.$message.error(err?.message || "获取组织结构隔离层级失败");
            });
        }
      });
    },
    getTemplateContent(templateId) {
      apiGetOrgTemplateContent(templateId).then((res) => {
        this.treeData = res.children;
        this.orgStatus = 0;
        this.checkedItems = [];
      });
    },
    handleTemplateCheck(item = {}) {
      this.checkedTemplate = item;
    },
    handleSave() {
      if (this.checkedItems.length === 0)
        return this.$message.warning("请选择组织层级");
      this.$confirm("你确定要使用该模板吗？保存后无法进行修改。")
        .then(() => {
          const nodes = this.checkedItems.map((item) => {
            const { id, pid, name, code } = JSON.parse(item);
            return { id, pid, name, code };
          });
          apiSetOrgTemplate({
            tenantId: this.tenantId,
            nodes,
          }).then(() => {
            // this.$message.success("操作成功");
            this.templateList = [];
            this.orgStatus = 1;
            this.getOrgStruct().then(() => {
              if (this.$has("utos:tenant:maintain")) {
                this.updateIsolation();
              }
            });
          });
        })
        .catch(() => {});
    },
    updateIsolation() {
      this.$refs.updateDialog?.showDialog();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-main-left {
  .model-list {
    margin-top: 20px;
    .model-item {
      padding-left: 12px;
      width: 300px;
      height: 58px;
      background: #f0f0f0;
      border-radius: 8px;
      font-size: 14px;
      color: #666666;
      line-height: 58px;
      &.checked {
        background: #ebedfa;
        color: #4d6bff;
      }
      &.active {
        position: relative;
        &::after {
          content: "已应用";
          top: 0;
          right: 0;
          display: block;
          position: absolute;
          width: 64px;
          height: 20px;
          background: #4d6bff;
          border-radius: 0px 8px 0px 8px;
          font-size: 12px;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
.btn-group {
  margin-top: 40px;
  .el-button {
    margin-right: 20px;
  }
}
.page-main-right {
  display: flex;
  flex-direction: column;
  .tip {
    font-size: 14px;
    font-weight: 600;
    color: #222222;
    line-height: 21px;
    margin-bottom: 20px;
  }
  ::v-deep .el-tree {
    flex: 1;
    overflow: scroll;
  }
}
.custom-tree-node {
  font-size: 14px;
  color: #666666;
  line-height: 21px;
  .iconfont {
    padding-left: 15px;
    color: #ffac05;
    font-size: 12px;
  }
}
</style>
