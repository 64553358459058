/* 1、地址最长输入字符限制 */
<template>
  <div>
    <gc-dialog :title="dialogTitle" :show.sync="visible" width="610px">
      <template>
        <el-form
          ref="tenantModelForm"
          :rules="rules"
          :model="tenantModel"
          :disabled="status === 'check'"
          label-position="top"
        >
          <div class="pale-title" style="margin-top: 5px">基本信息</div>
          <template>
            <el-form-item label="租户名称" prop="tenantName">
              <el-input
                size="small"
                v-model.trim="tenantModel.tenantName"
                placeholder="请输入"
                @blur="handleBlur('tenantName')"
              />
            </el-form-item>
            <el-form-item label="子域名" prop="subdomain">
              <el-input
                size="small"
                v-model.trim="tenantModel.subdomain"
                placeholder="请输入"
                @blur="handleBlur('subdomain')"
              >
                <!-- <template slot="append">.cn</template> -->
              </el-input>
            </el-form-item>
            <el-form-item label="是否开启组织机构" prop="orgEnable">
              <el-select
                size="small"
                v-model="tenantModel.orgEnable"
                placeholder="请选择"
                :disabled="status !== 'add'"
              >
                <el-option
                  v-for="item in options.orgOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="租户编号" prop="additionalInfo.customerNumber">
              <el-input
                size="small"
                v-model="tenantModel.additionalInfo.customerNumber"
                placeholder="请输入"
              />
            </el-form-item>

            <el-form-item label="租户类型" prop="tenantType">
              <el-select
                size="small"
                v-model="tenantModel.tenantType"
                placeholder="请选择"
                :disabled="status !== 'add'"
              >
                <el-option
                  v-for="item in [
                    { label: '燃气', value: '0' },
                    { label: '水务', value: '1' },
                  ]"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="!tenantModel.orgEnable"
              label="所在省份"
              prop="additionalInfo.province"
            >
              <el-select
                size="small"
                v-model="tenantModel.additionalInfo.province"
                placeholder="请选择"
                @change="handleProvinceChange"
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.regionCode"
                  :label="item.regionName"
                  :value="item.regionCode"
                  @click.native="tenantModel.additionalInfo.city = ''"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="!tenantModel.orgEnable"
              label="所在市"
              prop="additionalInfo.city"
            >
              <el-select
                size="small"
                v-model="tenantModel.additionalInfo.city"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cityOptions"
                  :key="item.regionCode"
                  :label="item.regionName"
                  :value="item.regionCode"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="联系人" prop="additionalInfo.contact">
              <el-input
                size="small"
                v-model="tenantModel.additionalInfo.contact"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="联系电话" prop="additionalInfo.phone">
              <el-input
                size="small"
                :maxlength="11"
                v-model="tenantModel.additionalInfo.phone"
                placeholder="请输入"
              />
            </el-form-item>
          </template>
          <template v-if="status === 'add'">
            <div class="split-line" />
            <div class="pale-title">账户信息</div>
            <el-form-item label="登录账号" class="margin-right-30px">
              <el-input v-model="account.name" disabled placeholder="请输入" />
            </el-form-item>
            <el-form-item label="登录密码" required class="original">
              <el-input
                v-model="account.password"
                placeholder="******"
                disabled
              />
            </el-form-item>
          </template>
        </el-form>
      </template>
      <template #footer>
        <div class="" v-if="status === 'add'">
          <el-button round @click="cancelAdd">取消</el-button>
          <el-button round @click="confirmAdd" type="primary">新增 </el-button>
        </div>
        <div class="" v-else-if="status === 'check'">
          <el-button
            type="primary"
            round
            @click="status = 'edit'"
            v-has="'utos:tenant:maintain'"
            >编辑</el-button
          >
        </div>
        <div class="" v-else-if="status === 'edit'">
          <el-button round @click="cancelEdit">取消</el-button>
          <el-button round @click="updateTenant" type="primary">保存</el-button>
        </div>
      </template>
    </gc-dialog>
  </div>
</template>

<script>
import { ruleRequired, ruleMaxLength } from "@/utils/rules";
import { apiAddTenant, apiModifyTenant } from "@/api/tenant.js";
import { apiGetCityList, apiGetProvinceList } from "@/api/account";
export default {
  name: "AddTenant",
  components: {},
  props: {
    departmentTree: {},
  },
  data() {
    return {
      tenantModel: {
        tenantName: "",
        subdomain: "",
        orgEnable: false,
        tenantType: "0",
        additionalInfo: {
          contact: "",
          phone: "",
          customerNumber: "",
          province: "",
          city: "",
        },
      },
      rules: {
        tenantName: [
          ruleRequired("请输入租户名称"),
          ruleMaxLength(32, "租户名称"),
        ],
        tenantType: [ruleRequired("请选择租户类型")],
        subdomain: [ruleRequired("请输入子域名"), ruleMaxLength(24, "子域名")],
        orgEnable: [ruleRequired("请选择")],
        account: [ruleRequired("请输入登录账号"), ruleMaxLength(32)],
        "additionalInfo.contact": [ruleMaxLength(32, "联系人名称")],
        "additionalInfo.phone": [ruleMaxLength(18, "联系电话")],
        "additionalInfo.customerNumber": [ruleMaxLength(32, "租户编号")],
        "additionalInfo.province": [ruleRequired("请选择省份")],
        "additionalInfo.city": [ruleRequired("请选择市")],
      },
      visible: false,
      status: "add", // check edit
      initialDetail: {},
      provinceList: [],
      cityOptions: [],
    };
  },

  computed: {
    account() {
      const { subdomain } = this.tenantModel;
      return {
        name: subdomain ? subdomain + "admin" : "",
        password: "jk300349",
      };
    },
    options() {
      const orgOptions = [
        {
          label: "开启",
          value: true,
        },
        {
          label: "不开启",
          value: false,
        },
      ];
      return {
        orgOptions,
      };
    },
    dialogTitle() {
      return {
        add: "新增租户",
        edit: "编辑租户信息",
        check: "租户详情",
      }[this.status];
    },
    provinceCityName() {
      const { province, city } = this.tenantModel.additionalInfo;
      if (!city) {
        return {
          provinceName: "",
          cityName: "",
        };
      }
      const targetProvince = this.provinceList.find(
        (o) => o.regionCode === province
      );
      const targetCity = this.cityOptions.find((o) => o.regionCode === city);
      return {
        provinceName: targetProvince.regionName,
        cityName: targetCity.regionName,
      };
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.$refs.tenantModelForm?.resetFields();
        this.tenantModel = this.$options.data().tenantModel;
      }
    },
  },
  created() {
    apiGetProvinceList().then((res) => {
      this.provinceList = res.records;
    });
  },
  mounted() {},
  methods: {
    handleBlur(key) {
      this.$set(
        this.tenantModel,
        key,
        this.tenantModel[key].replace(/\s+/g, "")
      );
    },
    show(status, info = null) {
      this.visible = true;
      this.status = status;
      if (info && status === "check") {
        this.initialDetail = {
          ...info,
          additionalInfo: {
            ...this.tenantModel.additionalInfo,
            ...(info.additionalInfo || {}),
          },
          tenantName: info.name,
          tenantType: info.tenantType || "0",
        };
        const {
          additionalInfo: { phone },
        } = this.initialDetail;
        (this.initialDetail.additionalInfo.phone = phone ? phone + "" : ""),
          (this.tenantModel = this._.cloneDeep(this.initialDetail));
        const provinceCode = this.tenantModel.additionalInfo.province;
        if (provinceCode) {
          apiGetCityList(provinceCode).then(({ records }) => {
            this.cityOptions = records;
          });
        }
      }
    },
    confirmAdd() {
      const { additionalInfo, ...rest } = this.tenantModel;
      this.$refs.tenantModelForm.validate((valid) => {
        if (valid) {
          apiAddTenant({
            ...additionalInfo,
            ...rest,
            additionalInfo: {
              ...this.provinceCityName,
              province: additionalInfo.province,
              city: additionalInfo.city,
            },
          }).then(() => {
            this.$message.success("新增成功");
            this.$emit("emit-refresh");
            this.visible = false;
          });
        }
      });
    },
    cancelEdit() {
      this.status = "check";
      this.tenantModel = this._.cloneDeep(this.initialDetail);
    },
    cancelAdd() {
      this.visible = false;
    },
    updateTenant() {
      const { additionalInfo, ...rest } = this.tenantModel;
      this.$refs.tenantModelForm.validate((valid) => {
        if (valid) {
          apiModifyTenant({
            ...additionalInfo,
            ...rest,
            tenantId: this.tenantModel.id,
            additionalInfo: {
              ...this.provinceCityName,
              province: additionalInfo.province,
              city: additionalInfo.city,
            },
          }).then(() => {
            this.$message.success("修改成功");
            this.$emit("emit-refresh");
            this.visible = false;
          });
        }
      });
    },
    handleProvinceChange(val) {
      if (!val) return;
      apiGetCityList(val).then(({ records }) => {
        this.cityOptions = records;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.split-line {
  width: 100%;
  border-bottom: 0.5px dashed #ccc;
  height: 0;
}
.pale-title {
  margin: 19px 0 20px;
  flex: 0 0 100%;
  font-size: 14px;
  color: #ababab;
  line-height: 14px;
}
::v-deep .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item {
    flex: 0 0 calc((100% - 30px) / 2);
    margin-right: 0;
    &:nth-child(2n),
    &.margin-right-30px {
      // margin-right: 30px;
    }
    &.original {
      margin-right: 0;
    }
    &.col-12 {
      flex: 0 0 100%;
      margin-right: 0;
    }
    .el-select {
      width: 100%;
    }
    .el-form-item__label {
      line-height: 14px;
      height: 14px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}
</style>
