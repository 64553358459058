<template>
  <div class="page">
    <div class="permission page-main" v-loading.lock="loading">
      <div class="page-main-right">
        <el-tree
          ref="funTree"
          :data="treeData"
          show-checkbox
          node-key="id"
          :default-expand-all="true"
          :default-checked-keys="checkedKeys"
          :props="{ children: 'children', label: 'name' }"
        >
        </el-tree>
        <div class="btn-group">
          <template v-if="status === 'edit'">
            <el-button
              plain
              round
              size="large"
              @click="handleSaveClick"
              v-click-blur
              >保存</el-button
            >
            <el-button
              round
              v-click-blur
              size="large"
              style="margin-right: 24px"
              @click="handleCancelClick"
              >取消</el-button
            >
          </template>
          <template v-else>
            <el-button
              v-click-blur
              type="primary"
              round
              size="large"
              @click="handleEditClick"
              >编辑</el-button
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiGetTenantFunctionTree,
  apiGetTenantGrantedFunction,
  apiSetTenantFunction,
} from "@/api/tenant";
export default {
  name: "Permission",
  components: {},
  props: {},
  data() {
    return {
      treeData: [],
      defaultCheckedKeys: [],
      checkedKeys: [],
      status: "view",
      loading: false,
    };
  },
  computed: {
    tenantId() {
      return this.$route.query.tenantId;
    },
  },
  created() {
    this.getSysFunctionTree();
  },
  activated() {
    this.treeData.length !== 0 && this.getTenantGrantedFunction();
  },
  methods: {
    handleCancelClick() {
      this.status = "view";
      this.toggleTreeStatus(true);
      this.checkedKeys = this._.cloneDeep(this.defaultCheckedKeys);
      this.$refs.funTree?.setCheckedKeys(this.checkedKeys);
    },
    handleSaveClick() {
      const keys = this.$refs.funTree.getCheckedKeys(true);
      if (keys.length === 0)
        return this.$message.warning("至少勾选一个功能权限");
      apiSetTenantFunction({
        functionalIds: keys,
        tenantId: this.tenantId,
      }).then(() => {
        this.getTenantGrantedFunction();
      });
    },
    handleEditClick() {
      this.status = "edit";
      this.toggleTreeStatus(false);
    },
    getSysFunctionTree() {
      // 获取系统功能权限树
      apiGetTenantFunctionTree({
        namespaces: "utos,iot-monitor",
      }).then((res) => {
        this.treeData = res.children;
        this.getTenantGrantedFunction();
      });
    },
    toggleTreeStatus(disabled) {
      const helper = (list, disabled) => {
        list.forEach((item) => {
          this.$set(item, "disabled", disabled);
          item.children && helper(item.children, disabled);
        });
      };
      helper(this.treeData, disabled);
    },
    getTenantGrantedFunction() {
      this.loading = true;
      apiGetTenantGrantedFunction({
        tenantId: this.tenantId,
        namespaces: "utos,iot-monitor",
      })
        .then((res) => {
          this.checkedKeys = res;
          this.status = res.length > 0 ? "view" : "edit";
          this.toggleTreeStatus(res.length > 0);
          this.defaultCheckedKeys = this._.cloneDeep(res);
          this.treeData?.length && this.$refs.funTree?.setCheckedKeys(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.permission {
  .page-main-left {
    .template-list {
      margin-top: 20px;
      max-height: calc(100% - 85px);
      overflow: scroll;
      .template {
        margin-bottom: 10px;
        padding-left: 12px;
        width: 300px;
        height: 40px;
        background: #f0f0f0;
        border-radius: 8px;
        font-size: 14px;
        color: #666666;
        line-height: 40px;
        &.active {
          background: #ebedfa;
          color: #4d6bff;
          position: relative;
          &::after {
            content: "已应用";
            top: 0;
            right: 0;
            display: block;
            position: absolute;
            width: 64px;
            height: 20px;
            background: #4d6bff;
            border-radius: 0px 8px 0px 8px;
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
            text-align: center;
          }
        }
        &.checked {
          background: #ebedfa;
          color: #4d6bff;
        }
      }
    }
  }
  .page-main-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-tree {
      flex: 1;
      overflow: scroll;
    }
    .btn-group {
    }
  }
}
</style>
